import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Modal from '@material-ui/core/Modal';
import Img from 'gatsby-image/withIEPolyfill';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const Container = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #333;
  border: 1px solid #000;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);

  width: 300px;

  @media (min-width: 350px) {
    width: 350px;
  }

  @media (min-width: 400px) {
    width: 400px;
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const ModalWrapper = () => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const data = useStaticQuery(graphql`
    query {
      bannerModal: file(relativePath: { eq: "banner-modal2.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleClose}
      disableAutoFocus
      disableEnforceFocus
    >
      <Container>
        <Img
          fluid={data.bannerModal.childImageSharp.fluid}
          objectFit="cover"
          alt="Promoção compre 2 pizza ganhe um refrigerante"
        />
        <ButtonWrapper>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleClose}
            disableElevation
          >
            Fechar
          </Button>
        </ButtonWrapper>
      </Container>
    </Modal>
  );
};

export default ModalWrapper;
