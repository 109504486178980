import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled, { createGlobalStyle } from 'styled-components';

import Header from './header';
import WhatsappButton from './WhatsappButton';
import ModalWrapper from './modalWrapper';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #ccc;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const Container = styled.div`
  background-color: #131313;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 10px 10px 10px -7px rgba(0, 0, 0, 0.51);

  @media (min-width: 1024px) {
    margin: 30px auto;
    /* border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px; */
    border-radius: 6px;
  }
`;

const Footer = styled.footer`
  margin: 15px auto 0;
  padding-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.75rem;
  color: #ccc;

  a {
    text-decoration: underline;
  }
`;

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <GlobalStyle />

        <ModalWrapper />

        <Container>
          <Header siteTitle={data.site.siteMetadata.title} />

          {children}

          <Footer>
            <p>
              © {new Date().getFullYear()} - {data.site.siteMetadata.title}
            </p>

            <p>
              Cardápio desenvolvido por{' '}
              <a href="https://mznapplab.com">MZN App Lab</a>
            </p>
          </Footer>
        </Container>

        <WhatsappButton />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
