import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map } from 'styled-icons/boxicons-regular/Map';
import { PhoneCall } from 'styled-icons/boxicons-regular/PhoneCall';
import { Facebook } from 'styled-icons/boxicons-logos/Facebook';
import { Instagram } from 'styled-icons/boxicons-logos/Instagram';
import { Clock } from 'styled-icons/fa-regular/Clock';
import { CreditCard } from 'styled-icons/boxicons-solid/CreditCard';
import { Whatsapp } from 'styled-icons/boxicons-logos/Whatsapp';

import bg from '../images/bg.jpg';

const HeaderWrapper = styled.header`
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(${bg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: #ffffff;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;

  @media (min-width: 1024px) {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
`;

const Container = styled.div`
  padding-left: 15px;
  padding-right: 15px;

  display: grid;
`;

const Social = styled.div`
  padding: 9px 0;

  a {
    padding: 0 5px;
  }
`;

const Phones = styled.p`
  font-size: 1.25rem;
  margin-bottom: 0;

  span {
    white-space: nowrap;
  }
`;

const ImageWrapper = styled.div`
  .gatsby-image-wrapper {
    max-width: 350px;
    margin: 15px auto;
  }
`;

const Hours = styled.p`
  margin-top: 10px;
`;

const Address = styled.p``;

const IconWhatsapp = styled(Whatsapp)`
  margin: 0 2px 3px 2px !important;
`;

const IconFacebook = styled(Facebook)`
  background: #3b5998;
  color: #ffffff;
  padding: 3px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

const IconInstagram = styled(Instagram)`
  background: #d6249f;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  color: #ffffff;
  padding: 3px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

const IconMap = styled(Map)`
  background: #dd4b39;
  color: #ffffff;
  padding: 3px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

const CardMachine = styled.p`
  color: #ffffff;
  background-color: #094528;
  display: block;
  margin: 0 auto 25px;
  padding: 3px 5px;
  border-radius: 3px;
  font-weight: 400;
`;

const IconPhoneCall = styled(PhoneCall)`
  margin: 0 2px 3px 2px !important;
`;

const Header = ({ siteTitle }) => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 350, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <HeaderWrapper>
        <Container>
          <Social>
            <a
              href="https://www.facebook.com/PIZZADACASAIRACEMAPOLIS/"
              aria-label="Visitar página no Facebook"
            >
              <IconFacebook size={23} />
            </a>
            <a
              href="https://www.instagram.com/pizzadacasairacemapolis/"
              aria-label="Visitar página no Instagram"
            >
              <IconInstagram size={23} />
            </a>
            <a
              href="https://goo.gl/maps/X6pZJQRuvFXNMuedA"
              aria-label="Visitar local no Google Maps"
            >
              <IconMap size={23} />
            </a>
          </Social>

          <Phones>
            <IconPhoneCall size={30} />
            3456.5655 / 3456.5457 /{' '}
            <span>
              99750.4170 <IconWhatsapp size={30} />
            </span>
          </Phones>

          <ImageWrapper>
            <Img
              fluid={data.logo.childImageSharp.fluid}
              alt={`Logo ${siteTitle}`}
            />
          </ImageWrapper>

          <Hours>
            <Clock size={16} /> De Terça a Domingo a partir das 18h
          </Hours>

          <Address>
            <Map size={20} /> Rua Helena Modenez Pavan, 120 - Pq. José Modenez
          </Address>

          <CardMachine>
            <CreditCard size={23} /> Levamos a maquininha todos os dias
          </CardMachine>
        </Container>
      </HeaderWrapper>
    )}
  />
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
