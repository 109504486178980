import React from 'react';
import styled from 'styled-components';
import { Whatsapp } from 'styled-icons/boxicons-logos/Whatsapp';

const Button = styled.a`
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 1200;
`;

const WhatsappIcon = styled(Whatsapp)`
  margin-top: 14px;
`;

const WhatsappButton = () => (
  <Button
    href="https://api.whatsapp.com/send?phone=5519997504170"
    aria-label="Click aqui para fazer pedido via whatsapp"
  >
    <WhatsappIcon size={30} />
  </Button>
);

export default WhatsappButton;
